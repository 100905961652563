import { Link } from "react-router-dom";
import { Block, Row } from 'jsxstyle'
import { getCircleShortName } from '../util.js'

export default function AthleteInfo({athlete}) {

	const getStravaLink = () => {
        if (!athlete) return
        const link = "https://www.strava.com/athletes/" + athlete.strava.id
        return (
          <Row justify-content="center">
            <Link style={{fontSize: '15px', textDecoration: 'none', color: '#fc5200'}} to={link}>View on Strava</Link>
          </Row>
        )
     }

	return (
		<>
			{athlete && athlete.strava && (
				<div id="name" style={{ textAlign: 'center', paddingBottom: '8px', marginTop: '-10px'}}>
                    {athlete.strava.profile && (
    					<Row justify-content="center" style={{paddingBottom: '2px'}} >
    						<img src={athlete.strava.profile} alt="Profile Image" style={{borderRadius: "50%", width: '30px', marginRight: "4px"}}/>
    					</Row>
                    )}
					<Row justify-content="center" style={{paddingBottom: '0px', letterSpacing: '0.5px', fontSize: '22px', fontWeight: 330, lineHeight: '25px'}} >
						{athlete.strava.firstname} {athlete.strava.lastname}
					</Row>
					<Row justify-content="center" style={{paddingBottom: '1px', fontSize: '17px', fontWeight: 330, lineHeight: '25px'}} >
						{getCircleShortName(athlete)}
					</Row>
					{getStravaLink()}
				</div>
			)}
		</>
	)

}
