import React from "react";
import { useRef, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Block, Row, Col } from 'jsxstyle'
import Autocomplete from "react-google-autocomplete";

const API_URL = process.env.REACT_APP_API_URL

export default function Geocode({ lookups, setLookups, circles, setCircles, pretty, saveAthlete, setIsLoading }) {

  const inputStyle = {
    boxShadow: 'inset 0 0 10px #eee !important',
    border: '2px solid #eee',
    width: '256px',
    height: '40px',
    marginLeft: '0px',
    fontWeight: '300 !important',
    outline: 'none',
    padding: '10px 20px',
    marginBottom: '10px',
  }

  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    fields: ["address_components", "geometry", "place_id", "name"],
    types: ["locality", "sublocality", "neighborhood"]
  };

  const getGoogleProposal = async (lookupId) => {
    const url = API_URL + 'geocode/' + lookupId)
    const response = await fetch(url);
    const { circles , lookup } = await response.json()
    setCircles(circles)

		if (setLookups) {
			setLookups(lookup.address_components)
		}

  }

  const getProposals = async (lookupId) => {
    setIsLoading(true)
    try {
      //await getGeonamesProposal(lookupId)
      await getGoogleProposal(lookupId)
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } catch(err) {
      console.log('Err', err)
      setIsLoading(true)
    }
  }

	const reset = async () => {
		setLookups([])
		setCircles([])
	}

  return (
    <Block justify-content="center" style={{ margin: '30px', marginBottom: '10px', marginTop: '10px' }}>
  		{circles.length === 0 ? (
  			<Block>
  				<Row justify-content="center">
  					<Autocomplete
  	            apiKey='AIzaSyCaeiJgmewXeNyBzeCa6G6aYPMS-2JBvOs'
  	            style={inputStyle}
  	            options={options}
  	            onPlaceSelected={(place) => {
  	              if (place && place.place_id) {
  	                getProposals(place.place_id)
  	              } else {
  	                console.log('Error: no place_id')
  	              }
  	            }}
  	          	/>
  				</Row>
  			</Block>
  		) : (
        <Block>
  				{pretty ? (
  					<Block>
  						{(
  							circles.map((circle, i) => (
  								<div key={i} justify-content="center" style={{ textAlign: 'center', marginBottom: '0px', fontSize: '18px', lineHeight: '23px'}}>
  									{i+1}. {circle.name}
  								</div>
  							))
  						)}
  				 	</Block>
  				) : (
  					<Block>
  						<Row style={{ marginTop: '5px', fontSize: '18px'}}>
  							Lookup
  						</Row>

  						{lookups && (
  							lookups.map((lookup, i) => (
  								<div key={i} style={{fontWeight: 400}}>
  									<b>{lookup.long_name}</b> - {lookup.types.join(" , ")}
  								</div>
  							))
  						)}

  						<Row style={{ marginTop: '5px', fontSize: '18px'}}>
  							Circles
  						</Row>
  						{(
  							circles.map((circle, i) => (
                  <div key={i} style={{fontWeight: 400}}>
                    <b>{circle.name}</b> - {circle.types.join(" , ")}
                  </div>
                ))
  						)}
  						<Row justify-content="center" style={{ marginBottom: '5px', marginTop: '5px'}}>
  							<Link onClick={() => reset()}>
  								Test new place
  							</Link>
  						</Row>
  					</Block>
  				)}
        </Block>
    	)}
  	</Block>
  );

}
