import React from "react";
import { useRef, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Block, div, Col } from 'jsxstyle'
import LoadingSpinner from "../components/spinner";
import strava from '../../public/btn_strava_connectwith_light.png';
import Register from "./register";


export default function Login({user, setAthlete, setUser, login, setCircleIndex}) {

	const [loginError, setLoginError] = useState(null);

	//conquer boss rule
	//Climb with us and rule your circles

	//Your circles are geographical enities ranging from your neighbourhood (home circle) to circle planet.

	return (
		<Block>
			{user ? (
				<Register user={user} setAthlete={setAthlete} setUser={setUser} setCircleIndex={setCircleIndex}/>
			) : (
				<Block style={{ textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginTop: '3px', fontSize: '17px', lineHeight: '20px'}}>

					<div style={{ alignItems: 'center', marginBottom: '3px', fontSize: '28px', lineHeight: '28px', fontWeight: 400}}>
							Climb with us and rule
					</div>
					<div style={{ alignItems: 'center', marginBottom: '14px', fontSize: '26px', lineHeight: '28px', fontWeight: 400}}>
							your circles
					</div>

					<div style={{ marginBottom: '6px'}}>
							Compete on elevation gain and <br/> HOD (Height Over Distance).
					</div>

					<div style={{ marginBottom: '6px'}}>
							Win trophies every day, week, month and year. Trophies are awarded to all your circles.
					</div>

					<div style={{ marginBottom: '6px'}}>
						Circles are geographical enities ranging from your neighbourhood or home town (your home circle) to circle planet.
					</div>

					<div style={{ marginBottom: '6px'}}>
							Compatible with Strava – your elevation data is fetched automatically.
					</div>

					<div style={{ marginBottom: '6px' }}>
							Read more about Heightmeters <Link to="/about" style={{ textDecoration: 'none' }}>here.</Link>
					</div>

					<div style={{ marginTop: '0px'}}>
							Use Strava to Login or Register at Heightmeters.com
					</div>

					<div justify-content="center" style={{ marginTop: '6px'}}>
						<Link onClick={() => login()}>
							<img src={strava} alt="Strava Login" style={{ width: '193px' }}/>
						</Link>
					</div>

				</Block>
			)}
			{loginError && (
				<div justify-content="center" style={{ margin: '14px'}}>
					{loginError}
				</div>
			)}
		</Block>
	)

}
