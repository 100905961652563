export const timePeriods = ['Yesterday', 'Today', 'Week', 'Month', 'Year']

export const getCircleShortName = (athlete) => {
  const name = [2]
  athlete.circles.forEach((circle, i) => {
    if (circle.level === 0) {
      name[0] = circle.name
    }
    if (circle.level === 1 && !name[0]) {
      name[0] = circle.name
    }
    if (circle.level === 4) {
      name[1] = circle.name
    }
  });
  return name.join(', ')
}

const getDateString = (date) => {
	const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : (date.getUTCMonth() + 1)
	const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()
	return '' + date.getUTCFullYear() + '-' + month + '-' + day
}

/*
export const getLocalTime = (gmtOffset) => {
 return newDate
}
*/

export const getLocalTime = (gmtOffset) => {
  const dt = new Date()
  return new Date(dt.setHours(dt.getUTCHours() + gmtOffset))
}


/*
export const getTime = (period, gmtOffset) => {
	//Kolla igenom denna - ta med offset
	const dt = new Date()
	if (period === 0 || period === 1) {
		dt.setDate(dt.getDate() -1);
		return {
			period: 'day',
			value: getDateString(dt)
		}
	}
	if (period === 2) {
		return {
			period: 'week',
			value: getWeek(dt)
		}
	}
	if (period === 3) {
		return {
			period: 'month',
			value: dt.getUTCMonth()
		}
	}
	if (period === 4) {
		return {
			period: 'year',
			value: dt.getUTCFullYear()
		}
	}
}
*/

export const getTimeFromDate = (date, period) => {

	const dt = new Date(date)

	if (period === 'day') {
		return dt.toLocaleString('default', { month: 'short' }) + ' ' + dt.getDate() + ' ' + dt.getFullYear()
	} else if (period === 'week') {
		return 'Week ' + getWeek(dt) + ' ' + dt.getFullYear()
	} else if (period === 'month') {
		return dt.toLocaleString('default', { month: 'long' }) + ' ' + dt.getFullYear()
	} else {
		return dt.getFullYear()
	}
}


export const format = (number) => {
  return number.toLocaleString().replace(',', ' ')
}

export const set = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value))
}

export const get = (key) => {
	const value = localStorage.getItem(key)
	return value ? JSON.parse(value) : null
}

export const unset = (key) => {
	localStorage.removeItem(key)
}

export const stringifyParams = (params) => {
    // Set up a new URLSearchParams object.
    const searchParams = new URLSearchParams()

    // Loop through the params object and append each key/value
    // pair to the URLSearchParams object.
    for (const [key, value] of Object.entries(params)) {
        searchParams.append(key, value)

        // If a key does not have a value, delete it.
        if (!searchParams.get(key)) {
            searchParams.delete(key)
        }
    }

    // Convert the URLSearchParams object to a string.
    const searchParamsString = searchParams.toString()

    // Replace the encoded commas with commas.
    const decodedSearchParamsString = searchParamsString.replace(/%2C/g, ',')

    return decodedSearchParamsString
}

export const getWeek = (currentDate) => {
	const startDate = new Date(currentDate.getFullYear(), 0, 1);
	startDate.setTime(startDate.getTime() + (60 * 60 * 1000 * 1))
	let days = ((currentDate - startDate + 1) / (24 * 60 * 60 * 1000));
	return Math.ceil(days / 7);
}
