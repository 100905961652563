import React from "react";
import { useRef, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Block, Row, Col } from 'jsxstyle'
import LoadingSpinner from "../components/spinner";
import LeaderBoard from "./leaderboard";
import Trophies from "./trophies";
import AthleteInfo from "./athlete-info";
import Achievements from "./achievements"
import Trophies from "./trophies";

export default function Athlete({ athlete, changeAthlete, circleIndex, setCircleIndex, circle, getCircle, sorted, setSorted, period, updatePeriod }) {

	const [show, setShow] = useState('Leaderboard');

	const renderShowBar = () => {
		return (
			<Block>
				<Row justify-content="center" style={{ fontSize: '18px'}}>
					 {['Leaderboard', 'Trophies'].map((type, i) => (
						 <div key={i}>
							 {show === type ? (
								 <Link>
									 {type} {type === 'Trophies' && '(' + athlete.numberOfTrophies + ')'}
								 </Link>
							 ) : (
								 <>
									 {(type === 'Trophies' && athlete.numberOfTrophies === 0) ? (
										 <>
									 		{type} (0)
										</>
									 ) : (
										 <Link onClick={() => setShow(type)} style={{ textDecoration: 'none' }}>
											 {type} {type === 'Trophies' && '(' + athlete.numberOfTrophies + ')'}
										 </Link>
									 )}
								 </>
							 )}
								&nbsp;&nbsp;
						 </div>
					 ))}
				</Row>
			</Block>
		)
	}

	return (
		<Block>
			{athlete && (
				<>
				<AthleteInfo athlete={athlete} />
				<Achievements achievement={circle && circle.achievements ? circle.achievements.filter(achievement => achievement.athleteId === athlete._id) : null} period={period} />
				<Block style={{ marginTop: '5px' }}>
					{renderShowBar()}
					{show === 'Trophies' ? (
						<Row justify-content="center" style={{ marginTop: '4px' }}>
							<Trophies
								circleInfo={athlete.circles}
								athleteId={athlete._id}
							/>
						</Row>
					) : (
						<Row justify-content="center" style={{ marginTop: '10px' }}>
							<LeaderBoard
								changeAthlete={changeAthlete}
								circleIndex={circleIndex}
								period={period}
								circleInfo={athlete.circles}
								athleteId={athlete._id}
								updateCircleIndex={setCircleIndex}
								circle={circle}
								getCircle={getCircle}
								setSorted={setSorted}
								sorted={sorted}
								updatePeriod={updatePeriod}
							/>
						</Row>
					)}
					{athlete._id === '65ad6929e15b90db98833992' && (
						<Row justify-content="center" style={{fontSize: '16px'}}>
							<Link to="/add-user" style={{ textDecoration: 'none' }}>Test Geocode</Link>
						</Row>
					)}
				</Block>
				</>
			)}
		</Block>
	)

}
