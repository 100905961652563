import { Link } from "react-router-dom";
import { useState, useEffect } from 'react'
import { Block, Row, Col } from 'jsxstyle'
import LoadingSpinner from "./spinner";
import { timePeriods, getTimeFromDate } from '../util.js'

const API_URL = process.env.REACT_APP_API_URL

export default function CircleInfo({circle, changeAthlete}) {

 	const [allTimeHigh, setAllTimeHigh] = useState(null)
	const [isLoading, setIsLoading] = useState(false);
	const [allTimeHighPeriod, setAllTimeHighPeriod] = useState(0);

	useEffect(() => {
		async function getAthletes() {

			console.log('Get ATH athletes for period', allTimeHighPeriod)
			const allTimeHigh = circle.allTimeHigh.filter(ath => ath.period === allTimeHighPeriod)[0]

			if (!allTimeHigh) {
				console.log('No All Time High for', circle.name, 'period', allTimeHighPeriod)
				return
			}
			setIsLoading(true)

			//const athletesId = [allTimeHigh.HM.athleteId, allTimeHigh.HOD.athleteId]

			const athletesId = allTimeHigh.records.map(rec => rec.athleteId)
			console.log('Get All Time High Athletes', athletesId)

			const response = await fetch(API_URL + 'athletes/short', {
	      method: 'POST',
	      headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json'
	      },
	      body: JSON.stringify(athletesId)
	    })
	    let athletes = await response.json()

			athletes.forEach((athlete, i) => {
				allTimeHigh.records.forEach((rec, j) => {
					if (athlete._id === rec.athleteId) {
						rec.strava = athlete.strava
					}
				});
			})

			console.log('allTimeHigh', allTimeHigh)
			setAllTimeHigh(allTimeHigh)
			setIsLoading(false);
    }
		getAthletes()
  }, [allTimeHighPeriod])

	const format = (value, type) => {
		return type === 'HOD' ? value.toFixed(2) : Math.round(value).toLocaleString().replace(',', ' ')
	}

	const formatPeriod = (value) => {
		if (allTimeHighPeriod === 0 ) return value.substring(4, value.length)
		if (allTimeHighPeriod === 1 ) {
			value = value.replace('Sun ', '')
			value = value.replace('Sat ', '')
			value = value.replace('Mon ', '')
			value = value.replace('2023', '')
			value = value.replace(' - ', '-')
			value = value.replace(', ', '')
			return value
		}
		return value
	}

	const renderTimeBar = () => {
		return (
			<Block>
				<Row justify-content="center" style={{ fontSize: '16px'}}>
			 {timePeriods.map((period, i) => (
				 <div key={i}>
					 {i === allTimeHighPeriod ? (
						 <Link>
							 {period}
						 </Link>
					 ) : (
						 <Link onClick={() => setAllTimeHighPeriod(i)} style={{ textDecoration: 'none' }}>
							 {period}
						 </Link>
					 )}
						&nbsp;&nbsp;
				 </div>
			 ))}
				</Row>
			</Block>
		)
	}

	const getChampion = (type) => {
		const ath = allTimeHigh.records.filter(rec => rec.type === type)[0]
		return (
			<>
				{ath && ath.strava && (
					<Block style={{  margin: '0px'}}>
						<Row justify-content="center" style={{ margin: '5px', fontSize: '18px' }}>
							{type === 'meters' ? 'Meters' : type}
						</Row>
						<Row justify-content="center" style={{paddingBottom: '2px'}} >
							<img src={ath.strava.profile } alt="Profile Image" style={{borderRadius: "50%", width: '20px', marginRight: "4px"}}/>
						</Row>
						<Row justify-content="center" style={{paddingBottom: '2px'}} >
							<Link onClick={() => {changeAthlete(ath.athleteId)}} style={{ textDecoration: 'none' }}>{ath.strava.firstname} {ath.strava.lastname}</Link>
						</Row>
						<Row justify-content="center" style={{ margin: '3px', fontSize: '20px' }}>
							{format(ath.value,type)}
						</Row>
						<Row justify-content="center" style={{ margin: '3px', fontSize: '16px' }}>
							{getTimeFromDate(ath.date, ath.period)}
						</Row>
					</Block>
				)}
			</>
		)
	}


	return (
		<Block style={{ fontWeight: 300, marginTop: '10px'}}>
			<Row justify-content="center" style={{ marginBottom: '11px', fontSize: '27px'}}>
				Circle {circle.name}
			</Row>
			<Row justify-content="center" style={{ margin: '7px', fontSize: '16px' }}>
				Number of Riders: {circle.numberOfRiders}
			</Row>
			<Row justify-content="center" style={{ margin: '7px', marginTop: '12px', fontSize: '22px' }}>
				All Time High
			</Row>

			{isLoading ? (
				<Row justify-content="center">
					<LoadingSpinner />
				</Row>
			) : (
				<>
				{allTimeHigh && (
					<Block style={{ marginBottom: '7px'}}>
						<Row justify-content="center" style={{ marginTop: '7px', marginBottom: '4px', fontSize: '18px' }}>
							{renderTimeBar()}
						</Row>
						<Row justify-content="center">
							<Col style={{ marginRight: '6px'}}>
								{getChampion('meters')}
							</Col>
							<Col style={{ marginLeft: '6px'}}>
								{getChampion('HOD')}
							</Col>
						</Row>
					</Block>
				)}
				</>

			)}

		</Block>
	)

}
