import React from "react";
import { useRef, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Block, Row, Col } from 'jsxstyle'
import Header from "../components/header";
import LoadingSpinner from "../components/spinner";

import Geocode from "../components/geocode";
import Autocomplete from "react-google-autocomplete";

const API_URL = process.env.REACT_APP_API_URL

export default function TestGeocode() {

  const [circles, setCircles] = useState([]);
  const [userName, setUserName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [athlete, setAthlete] = useState(null);
	const [lookups, setLookups] = useState([]);


  const saveAthlete = async () => {
    setIsLoading(true)

    const name = userName.split(' ')

    const params = {
      strava: {
        id: 19666608,
        firstname: name[0].trim(),
        lastname: (name.length > 1) ? name[1].trim() : '',
        profile: 'https://dgalywyr863hv.cloudfront.net/pictures/athletes/4483149/1425458/2/large.jpg'
      },
      circles: circles
    }

    const response = await fetch(API_URL + 'athletes', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    const athlete = await response.json()

    setAthlete(athlete)

    //localStorage.setItem('hm-user', JSON.stringify({_id: athlete._id}));
    setCircles([])
    setUserName('')
    console.log(athlete)
    setIsLoading(false)

  }

  const handleChange = (e) => {
    setUserName(e.target.value)
  }

  return (
		<Block>
			<Header />

			<Row justify-content="center" style={{ marginTop: '0px', marginLeft: '40px', marginRight: '40px'}}>
				{isLoading ? (
					<Row justify-content="center">
						<LoadingSpinner />
					</Row>
				) : (
					<Block style={{ marginTop: '0px', textAlign: 'center', fontWeight: 200, fontSize: '17px', lineHeight: '21px', maxWidth: '500px'}}>
						<Row justify-content="center" style={{ marginBottom: '15px', fontWeight: 400, fontSize: '24px', lineHeight: '24px'}}>
							Welcome Brajjan
						</Row>

						<Row justify-content="center" style={{ marginBottom: '10px'}}>
							To complete the registration you need to set up your circles.
						</Row>
						<Row justify-content="center" style={{ marginBottom: '10px'}}>
							Circles are geographical enities ranging from your neighbourhood or home town (home circle) to circle planet.
							You will compete against other members in all your circles respectively.
						</Row>
						<Row justify-content="center" style={{ marginBottom: '10px'}}>
							Use the form below to find the location that will define your home circle.
							The higher level circles is automatically created from that location.
						</Row>

						<Row>
							<Geocode lookups={lookups} setLookups={setLookups} setCircles={setCircles} circles={circles} setIsLoading={setIsLoading} />
						</Row>
					</Block>
				)}
			</Row>
		</Block>
	)

}
