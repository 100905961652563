import React from "react";
import { Block, Row } from 'jsxstyle'
import Header from "../components/header";
import { Link } from "react-router-dom";

export default function About() {

  return (
    <>
      <Header/>
			<Row justify-content="center">
	      <Block style={{ fontWeight: 300, lineHeight: '19px', fontSize: '17px', margin: '20px', marginTop:'0px', marginBottom: '10px', maxWidth: '500px' }}>

					<Block style={{ marginBottom: '15px'}}>

					<Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300}}>
	          Heightmeters
	        </Row>

	        <Row style={{ marginBottom: '8px'}}>
						Heightmeters is a direct translation from the Swedish word ’höjdmeter’.
						Höjd is translated into English as height, and meter means meters.
						So 'Höjdmeter' is a very efficient way to say 'Total elevation of climbing' and at same time it sounds kind of funny.
					</Row>
					<Row style={{ marginBottom: '5px'}}>
						So, how many heightmeters did you do today?
	        </Row>
				</Block>

					<Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
	          Registration
	        </Row>
	        <Row style={{ marginBottom: '8px'}}>
						Begin with creating a user by connecting with your Strava login. Your user name on Strava will be used on Heightmeters as well.
					</Row>

					<Row style={{ marginBottom: '15px' }}>
						Next you enter where you live.
						Heightmeters.com will make you member of circles.
						The smallest one is your home area (your home circle) and you automatically become a member of larger circles up to planet level, based on your home circle!!
	        </Row>

					<Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
						Circles and time zones
					</Row>

					<Row style={{ marginBottom: '15px'}}>
						Circles belongs to different time zones. If your home circle is 'Manhattan' it will be awarded a long time before 'North America' which will be your continent circle.
					</Row>

					<Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
						Trophies
					</Row>

					<Row style={{ marginBottom: '8px'}}>
						Once a day – right after midnight for a circle – trophies are 'awarded' for all rides made public on Strava up until midnight the day before.
						You can win trophies for 1st, 2nd and 3rd most heightmeters during the day, week, month, year and all time.
					</Row>

					<Row style={{ marginBottom: '8px'}}>
						Note that trophies is only awarded for circles with at least 3 members.
						A circle that has 3 or 4 members will have a trophy for 1st place.
						A circle with 5 to 9 members will have trophies for 1st and 2nd place.
						A circle with 10 members or more will have awards for 1st, 2nd and 3rd place.
					</Row>

					<Row style={{ marginBottom: '15px'}}>
						Rides are logged on the completion date of the ride.
						If you don’t log your ride before midnight the day after the ride is completed, the ride will not be used int the challenge for trophies that day.
						Trophies are awarded for all geographical circles, starting with your home circle and all the way up to planet level.
					</Row>

					<Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
						What we measure
					</Row>

					<Row style={{ marginBottom: '15px'}}>
						Not only heigtmeters are displayed, Heightmeters.com also calculates the HOD (Height Over Distance).
						It is the ‘average grade’ expressed in percentage of your ride or: 100 * heightmeters / distance in meters.
						The higher value the ‘tougher’ ride you’ve made in terms of climbing.
					</Row>

					<Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
						Measuring heightmeters
					</Row>

					<Row style={{ marginBottom: '15px'}}>
						Remember that all bike computers don’t estimate heighmeters the same.
						Numbers can differ between riders in the same group or if you ride the same ride on a different day.
						Calculating heightmeters isn’t easy, believe us!
						If it’s important to you to have as accurate numbers as possible – please use Strava’s Adjust Elevation feature (it’s called Correct Elevation in the web version of Strava).
						If everyone did, life would be a little more fair.
	        </Row>

					<Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
						Save to home screen
					</Row>

					<Row style={{ marginBottom: '0px', fontWeight: 400, fontSize: '15px' }}>
						iPhone
					</Row>
					<div style={{ marginBottom: '8px'}}>
						Tap <img src="https://help.apple.com/assets/656912ADA28BF1B7E90BF0F6/656912B3021EA3AD750FB887/sv_SE/d26fe35d3438fe81179a80c2b6c9b0c9.png" style={{ height: "15px", width: "10px", marginLeft: '1px', marginRight: '1px'}}/> in the menu bar. Then select "Add to Home Screen"
					</div>
					<Row style={{ fontWeight: 400, fontSize: '15px'}}>
						Android
					</Row>
					<Row style={{ marginBottom: '15px'}}>
						Tap the menu icon (3 dots in upper right-hand corner) and tap "Add to home screen"
					</Row>

					<Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
						Contact
					</Row>

					<div style={{ marginBottom: '15px'}}>
						Please contact us on at <Link to="mailto:heightmeters@gmail.com" style={{ textDecoration: 'none', color: '#444444' }}>heightmeters@gmail.com</Link> if you have any questions or thoughts about Heightmeters.
					</div>

					<Row style={{ marginBottom: '5px'}}>
						Please enjoy Hightmeters.com and remember – life is not fair!
					</Row>

	      </Block>
			</Row>
    </>
  );

}
