import React from "react";
import { useRef, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Block, Row, Col } from 'jsxstyle'
import LoadingSpinner from "../components/spinner";
import Geocode from "../components/geocode";
import styled from "styled-components";

const theme = {
  orange: {
    default: "#FC4C03",
    hover: "#FC4C03",
  }
};

const Button = styled.button`
  background-color: ${(props) => theme[props.theme].default};
  color: white;
	font-size: 15px;
  padding: 10px;
  border-radius: 5px;
  outline: 0;
  border: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${(props) => theme[props.theme].hover};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

Button.defaultProps = {
  theme: "orange",
};

const API_URL = process.env.REACT_APP_API_URL

export default function Register({user, setAthlete, setUser, setCircleIndex}) {

	const [circles, setCircles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
	const [tempAthlete, setTempAthlete] = useState(null);
	const [tempUser, setTempUser] = useState(null);

	/*
	const params = {
		strava: {
			id: 4483149,
			firstname: 'Lance',
			lastname: 'Manning',
			profile: 'https://dgalywyr863hv.cloudfront.net/pictures/athletes/4483149/1425458/2/large.jpg',
			sex: 'M'
		},
		circles: circles
	}
	*/

	const start = async () => {
		setCircleIndex(0)
		setAthlete(tempAthlete)
		setUser(tempAthlete)
		setTempAthlete(null)
		setTempUser(null)
	}

  const saveAthlete = async () => {
		console.log('Gonna save Athlete')
    setIsLoading(true)

    const params = {
      strava: {
				id: user.id,
				firstname: user.firstname,
				lastname: user.lastname,
				profile: user.profile,
				sex: user.sex,
				expires_at: user.expires_at,
	      expires_in: user.expires_in,
	      access_token: user.access_token,
	      refresh_token: user.refresh_token
			},
      circles: circles
    }
		//console.log('New athlete', params)

    const response = await fetch(API_URL + 'athletes', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    const athlete = await response.json()
		localStorage.setItem('hm-user', JSON.stringify({_id: athlete._id}));
    setTempAthlete(athlete)
		setTempUser(athlete)
		setTimeout(() => {
    	setIsLoading(false)
		}, 200)
  }

	return (
    <Block>
      <Row justify-content="center" style={{ marginTop: '0px', marginLeft: '40px', marginRight: '40px'}}>
	      {isLoading ? (
	        <Row justify-content="center">
	          <LoadingSpinner />
	        </Row>
	      ) : (
					<Block style={{ marginTop: '0px'}}>
							{tempAthlete ? (
								<Block style={{ marginBottom: '15px', fontSize: '17px', lineHeight: '21px'}}>
									<Row justify-content="center" style={{ textAlign: 'center', marginBottom: '10px', fontWeight: 400, fontSize: '26px', lineHeight: '26px'}}>
										Congratulations
									</Row>
									<Row style={{textAlign: 'center',  marginBottom: '8px'}}>
										Your registration is now complete and you can start enjoying Heightmeters.
									</Row>
									<Row style={{ textAlign: 'center', marginBottom: '8px'}}>
										In order to facilitate the usage of Heightmeters make sure to save it on your Home Screen:
									</Row>
									<Row justify-content="center" style={{ textAlign: 'center', marginBottom: '0px', fontWeight: 400, fontSize: '15px' }}>
										iPhone
									</Row>
									<div style={{ textAlign: 'center', marginBottom: '8px'}}>
										Tap <img src="https://help.apple.com/assets/656912ADA28BF1B7E90BF0F6/656912B3021EA3AD750FB887/sv_SE/d26fe35d3438fe81179a80c2b6c9b0c9.png" style={{ height: "15px", width: "10px", marginLeft: '1px', marginRight: '1px'}}/> in the menu bar. Then select "Add to Home Screen"
									</div>
									<Row justify-content="center" style={{ textAlign: 'center', fontWeight: 400, fontSize: '15px'}}>
										Android
									</Row>
									<Row style={{ textAlign: 'center', marginBottom: '12px'}}>
										Tap the menu icon (3 dots in upper right-hand corner) and tap "Add to home screen"
									</Row>
									<Row justify-content="center">
										<Button onClick={start}>Lets Go!!</Button>
									</Row>
								</Block>
							) : (
								<>
			          <Block style={{ marginBottom: '15px' }}>
									{circles && circles.length === 0 ? (
										<>
					            <Row justify-content="center" style={{ marginBottom: '15px', fontWeight: 400, fontSize: '24px', lineHeight: '24px'}}>
					              Welcome {user.firstname} {user.lastname}
					            </Row>

											<Row justify-content="center" style={{ marginBottom: '10px'}}>
												To complete the registration you need to set up your circles.
											</Row>
											<Row justify-content="center" style={{ marginBottom: '10px'}}>
												Circles are geographical enities ranging from your neighbourhood or home town (home circle) to circle planet.
												You will compete against other members in all your circles respectively.
											</Row>
											<Row justify-content="center" style={{ marginBottom: '10px'}}>
												Use the form below to find the location that will define your home circle.
												The higher level circles is automatically created from that location.
											</Row>
											
										</>
									) : (
										<Row justify-content="center" style={{ marginBottom: '15px', fontWeight: 400, fontSize: '24px', lineHeight: '24px'}}>
			                Your circles
			              </Row>
									)}
			          </Block>
			          <Geocode setCircles={setCircles} circles={circles} pretty={true} saveAthlete={saveAthlete} setIsLoading={setIsLoading}/>
								{circles && circles.length > 0  && (
									<>
										<Row justify-content="center" style={{ marginBottom: '4px', marginTop: '7px'}}>
								    	<Button onClick={saveAthlete}>Looks great - create me!!</Button>
										</Row>
										<Row justify-content="center" style={{ marginBottom: '5px', marginTop: '5px'}}>
											<Link style={{ textDecoration: 'none', fontSize: '15px', lineHeight: '20px'}} onClick={() => setCircles([])}>
												No, I want to choose a new location
											</Link>
										</Row>
									</>
								)}
							</>
						)}
	        </Block>
	      )}
	      </Row>
      </Block>
  );

}
