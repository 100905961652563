import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Block, Row } from 'jsxstyle'
import LoadingSpinner from "../components/spinner";
import Header from "../components/header";
import Login from "../components/login";
import Athlete from "../components/athlete";
import Circle from "../components/circle";
import Embedded from "../components/embedded";
import { oauth } from '../oauth'
import { getCircleShortName, getTime } from '../util.js'

export default function Home() {
 const [period, setPeriod] = useState(1);
 const [user, setUser] = useState(null);
 const [athlete, setAthlete] = useState(null);
 const [circleIndex, setCircleIndex] = useState(2);
 const [circle, setCircle] = useState(null);
 const [isLoading, setIsLoading] = useState(false);
 const [sorted, setSorted] = useState('Meters');
 const [showACircle, setShowACircle] = useState(false)
 const [embeddedBrowser, setEmbeddedBrowser] = useState(false)

 const API_URL = process.env.REACT_APP_API_URL

 useEffect(() => {
   async function getAthlete(user) {
       //console.log('Get athlete ' + user._id)
	 //alert('Get athlete ' + user._id)
     try {
       const response = await fetch(API_URL + 'athletes/' + user._id);
       const athlete = await response.json()
       if (athlete && athlete.strava) {
        setTimeout(() => {
          setCircleIndex(0)
          setUser(athlete)
          setAthlete(athlete);
          setIsLoading(false);
        }, 200);
       } else {
         localStorage.removeItem('hm-user')
         setIsLoading(false)
       }
     } catch(err) {
       //alert('There was an error ' + err.message)
			 console.log('There was an error ' + err.message)
			 //If no internet - dont remove 'hm-user
			 //localStorage.removeItem('hm-user')
       setIsLoading(false)
     }
   }

	 const agent = navigator.userAgent
	 const isEmbeddedBrowser = /FBAN|FBAV|FBMS|FB_IAB|FB4A|FBAN\/Messenger/.test(agent);
	 //setEmbeddedBrowser(true)
	 setEmbeddedBrowser(isEmbeddedBrowser)

   setIsLoading(true)
   const user = JSON.parse(localStorage.getItem('hm-user'))
	 const message = user ? 'User ' + user.strava.firstname + ' ' + user.strava.lastname : 'No user'
	 //alert(message)
   if (user && !athlete) {
     getAthlete(user)
   } else {
     setIsLoading(false)
   }
 }, [user])

 const goHome = () => {
	 setIsLoading(true);
	 if (user && user._id) {
		 setCircle(null)
		 setAthlete(user)
		 //setShowACircle(false)
		 setCircleIndex(0)
	 } else {
		 setUser(null)
	 }
	 setTimeout(() => {
     setIsLoading(false);
   }, 400);
 }

 const login = async () => {
	 //alert('login')
   try {
     const res = await oauth({
       scope: 'read,activity:read'
     })
     setIsLoading(true);
     const { code } = res
		 //alert('code ' + code)

     const response = await fetch(API_URL + 'strava/auth/' + code);
     const result = await response.json()

     if (result.athlete) {
       localStorage.setItem('hm-user', JSON.stringify(result.athlete))
       setCircleIndex(0)
       setUser(result.athlete)
       setAthlete(result.athlete);
       setIsLoading(false);
     } else if (result.strava) {
       setUser(result.strava)
       console.log('New user - continue registration', result)
     }

   } catch (err) {
		 //alert('There was an error ' + err.message)
		 console.log('There was an error ' + err.message)
   }
 }

 function logout () {
	 setIsLoading(true)
	 localStorage.removeItem('hm-user')
	 setTimeout(() => {
		 setCircle(null)
		 setAthlete(null)
		 setUser(null)
   }, 500);
 }

 const getCircle = async (id, period, sorted) => {
   const url = API_URL + 'circles/' + id
	 const date = new Date()
	 const timezoneOffset = date.getTimezoneOffset()
   let params = {
		 period: period,
     sort: sorted,
     athleteId: athlete._id,
		 athleteName: athlete.strava.firstname + ' ' + athlete.strava.lastname,
		 date: date.toString(),
		 timezoneOffset: timezoneOffset
   }
   const response = await fetch(url, {
     method: 'POST',
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json'
     },
     body: JSON.stringify(params)
   })
   const newCircle = await response.json()
   setCircle(newCircle)
 }

/* DELETE ATHLETE
 const deleteAthlete = async () => {
	 setIsLoading(true)
	 const url = API_URL + 'athletes/' + user._id
	 const response = await fetch(url, {
     method: 'DELETE'
   })
	 localStorage.removeItem('hm-user')
	 setAthlete(null)
	 setUser(null)
	 setTimeout(() => {
     setIsLoading(false);
   }, 100);
 }

 { process.env.NODE_ENV === 'development' && athlete._id === user._id && (
	 <Row justify-content="center" style={{ marginTop: "0px" }}>
		 <Link style={{ textDecoration: 'none', color: '#444444', fontSize: '15px'}} onClick={() => deleteAthlete()}>
			 Delete athlete
		 </Link>
	 </Row>
 )}
 */


 const changeAthlete = async (id) => {
	 //console.log('changeAthlete', id)
   setIsLoading(true)
   setCircle(null);
   //setShowACircle(false)
   const response = await fetch(API_URL + 'athletes/' + id);
   const athlete = await response.json()
   setCircleIndex(0)
   setAthlete(athlete);
   setTimeout(() => {
     setIsLoading(false);
   }, 300);
 }

 const updatePeriod = async (period) => {
   setPeriod(period)
   await getCircle(circle._id, period, sorted)
 }

 /* CIRCLE
  const showCircle = async (circleId) => {
 	 if (circleId) {
 		 getCircle(circleId, period, sorted)
 	 }
 	 console.log('Gobba sow circle', circleId)
 	 setShowACircle(true)
  }

	{showACircle && (
		<Circle
				circle={circle}
				getCircle={getCircle}
				changeAthlete={changeAthlete}
				sorted={sorted}
				setSorted={setSorted}
				period={period}
				updatePeriod={updatePeriod}
				/>
	)}
  */

    return (
        <>
            <Header profile={(athlete && user && user.strava) ? user.strava.profile : null} logout={logout} goHome={goHome} />
            <Row justify-content="center">
                <Block style={{ fontWeight: 300, lineHeight: '19px', fontSize: '18px', maxWidth: '500px'}}>
                    {isLoading ? (
                        <Row justify-content="center">
                            <LoadingSpinner />
                        </Row>
                    ) : (
                        <Block>
                            {embeddedBrowser ? (
                                <Embedded />
                            ) : (
                            <Block>
                                {athlete ? (
                                    <Athlete
                                        athlete={athlete}
                                        changeAthlete={changeAthlete}
                                        circleIndex={circleIndex}
                                        setCircleIndex={setCircleIndex}
                                        circle={circle}
                                        getCircle={getCircle}
                                        sorted={sorted}
                                        setSorted={setSorted}
                                        period={period}
                                        updatePeriod={updatePeriod}
                                        />
                                ) : (
                                    <Login user={user} setAthlete={setAthlete} setUser={setUser} setCircleIndex={setCircleIndex} login={login} />
                                )}
                            </Block>
                            )}
                        </Block>
                    )}
                </Block>
            </Row>
        </>
    );
}

//	<Login user={user} setAthlete={setAthlete} setUser={setUser} setCircleIndex={setCircleIndex} login={login} />
