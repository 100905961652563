import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Block, Row } from 'jsxstyle'
import LoadingSpinner from "../components/spinner";
import Header from "../components/header";
import Athlete from "../components/athlete";
import AthleteInfo from "../components/athlete-info";
import Embedded from "../components/embedded";
import { oauth } from '../oauth'
import { getCircleShortName, getTime } from '../util.js'

export default function TestAthlete() {
    const [period, setPeriod] = useState(1);
    const [user, setUser] = useState(null);
    const [athlete, setAthlete] = useState(null);
    const [circleIndex, setCircleIndex] = useState(2);
    const [circle, setCircle] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [sorted, setSorted] = useState('Meters');
    const [showACircle, setShowACircle] = useState(false)
    const [embeddedBrowser, setEmbeddedBrowser] = useState(false)
    const [show, setShow] = useState(0)

    const [searchParams] = useSearchParams();
    //const makeYear = searchParams.get("show");
    //console.log('makeYear', makeYear)


    const API_URL = process.env.REACT_APP_API_URL

    useEffect(() => {
        const makeYear = searchParams.get("show");
        setShow(makeYear ? makeYear :  0)
    }, [])

 useEffect(() => {
   async function getAthlete(user) {
		 console.log('Get athlete ' + user._id)
		 //alert('Get athlete ' + user._id)
     try {
       const response = await fetch(API_URL + 'athletes/' + user._id);
       const athlete = await response.json()
       if (athlete && athlete.strava) {
        setTimeout(() => {
          setCircleIndex(0)
          setUser(athlete)
          setAthlete(athlete);
          setIsLoading(false);
        }, 200);
       } else {
         localStorage.removeItem('hm-user')
         setIsLoading(false)
       }
     } catch(err) {
       //alert('There was an error ' + err.message)
			 console.log('There was an error ' + err.message)
			 //If no internet - dont remove 'hm-user
			 //localStorage.removeItem('hm-user')
       setIsLoading(false)
     }
   }



	 const agent = navigator.userAgent
	 const isEmbeddedBrowser = /FBAN|FBAV|FBMS|FB_IAB|FB4A|FBAN\/Messenger/.test(agent);
	 //setEmbeddedBrowser(true)
	 setEmbeddedBrowser(isEmbeddedBrowser)

   setIsLoading(true)
   const user = JSON.parse(localStorage.getItem('hm-user'))
	 const message = user ? 'User ' + user.strava.firstname + ' ' + user.strava.lastname : 'No user'
	 //alert(message)
   if (user && !athlete) {
     getAthlete(user)
   } else {
     setIsLoading(false)
   }
 }, [user])

 const goHome = () => {
	 setIsLoading(true);
	 if (user && user._id) {
		 setCircle(null)
		 setAthlete(user)
		 //setShowACircle(false)
		 setCircleIndex(0)
	 } else {
		 setUser(null)
	 }
	 setTimeout(() => {
     setIsLoading(false);
   }, 400);
 }

 const login = async () => {
	 //alert('login')
   try {
     const res = await oauth({
       scope: 'read,activity:read'
     })
     setIsLoading(true);
     const { code } = res
		 //alert('code ' + code)

     const response = await fetch(API_URL + 'strava/auth/' + code);
     const result = await response.json()
		 //alert('result ' + result.athlete.strava.firstname + ' ' + result.athlete.strava.lastname)

     if (result.athlete) {
       localStorage.setItem('hm-user', JSON.stringify(result.athlete))
       setCircleIndex(0)
       setUser(result.athlete)
       setAthlete(result.athlete);
       setIsLoading(false);
     } else if (result.strava) {
       setUser(result.strava)
       console.log('New user - continue registration', result)
     }

   } catch (err) {
		 //alert('There was an error ' + err.message)
		 console.log('There was an error ' + err.message)
   }
 }

 function logout () {
	 setIsLoading(true)
	 localStorage.removeItem('hm-user')
	 setTimeout(() => {
		 setCircle(null)
		 setAthlete(null)
		 setUser(null)
   }, 500);
 }

 const getCircle = async (id, period, sorted) => {
   const url = API_URL + 'circles/' + id
	 const date = new Date()
	 const timezoneOffset = date.getTimezoneOffset()
   let params = {
		 period: period,
     sort: sorted,
     athleteId: athlete._id,
		 athleteName: athlete.strava.firstname + ' ' + athlete.strava.lastname,
		 date: date.toString(),
		 timezoneOffset: timezoneOffset
   }
   const response = await fetch(url, {
     method: 'POST',
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json'
     },
     body: JSON.stringify(params)
   })
   const newCircle = await response.json()
   setCircle(newCircle)
 }


 const changeAthlete = async (id) => {
	 //console.log('changeAthlete', id)
   setIsLoading(true)
   setCircle(null);
	 //setShowACircle(false)
   const response = await fetch(API_URL + 'athletes/' + id);
   const athlete = await response.json()
   setCircleIndex(0)
   setAthlete(athlete);
   setTimeout(() => {
     setIsLoading(false);
   }, 300);
 }

 const updatePeriod = async (period) => {
   setPeriod(period)
   await getCircle(circle._id, period, sorted)
 }


  return (
	    <>
            <>
            {show > 3 ? (
	            <Header profile={(user && user.strava && user.strava.profile) ? user.strava.profile : null} logout={logout} goHome={goHome} />
            ) : (
                <div style={{ paddingBottom: "20px" }}>
                    <Header logout={logout} goHome={goHome} />
                </div>
            )}
            </>
	        <Row justify-content="center">
				<Block style={{ fontWeight: 300, lineHeight: '19px', fontSize: '18px', maxWidth: '500px'}}>
    	          {isLoading ? (
    	            <Row justify-content="center">
    	              <LoadingSpinner />
    	            </Row>
    	          ) : (
                    <Row>
        				<Block>
                            {athlete && show > 0 && show < 2 && (
                                <AthleteInfo athlete={athlete} />
                            )}
        				</Block>
                        <Block>
                            {athlete && show > 1 && (
                                <Athlete
                                    athlete={athlete}
                                    changeAthlete={changeAthlete}
                                    circleIndex={circleIndex}
                                    setCircleIndex={setCircleIndex}
                                    circle={circle}
                                    getCircle={getCircle}
                                    sorted={sorted}
                                    setSorted={setSorted}
                                    period={period}
                                    updatePeriod={updatePeriod}
                                />
                            )}
                        </Block>
                    </Row>
                )}
            </Block>
         </Row>
	    </>
  );
}
