import React, { useEffect, useState } from "react";
import { Block, Row } from 'jsxstyle'
import LoadingSpinner from "../components/spinner";
import { Link } from "react-router-dom";
import Header from "../components/header";

export default function Test() {

	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState(null);
	const [athlete, setAthlete] = useState(null);
	const [circle, setCircle] = useState(null);
	const [message, setMessage] = useState(null);
	const [embeddedBrower, setEmbeddedBrower] = useState(false)
	const [userAgent, setUserAgent] = useState(null);


	useEffect(() => {

    async function getAthlete(user) {

 		 console.log('Get athlete ' + user._id)
 		 //alert('Get athlete ' + user._id)
      try {
        const response = await fetch('https://test-api.heightmeters.com/athletes/' + user._id);
        const athlete = await response.json()
        if (athlete && athlete.strava) {
	         setTimeout(() => {
						 /*
	           setCircleIndex(0)
	           setUser(athlete)
	           setAthlete(athlete);
	           setIsLoading(false);
						 */
						 setMessage(athlete.strava.firstname + ' ' + athlete.strava.lastname)

						 console.log('athlete.strava', athlete.strava)
	         }, 200);
        } else {
          //localStorage.removeItem('hm-user')
          setIsLoading(false)
        }
      } catch(err) {
				const message = JSON.stringify(err)
				setMessage(message)
				setTimeout(() => {
					setIsLoading(false)
				}, 300);
      }
    }

		const agent = navigator.userAgent
		setUserAgent(agent)
		//setMessage('User Agent: ' + agent)
		console.log('User Agent', agent)
		const isEmbeddedBrowser = /FBAN|FBAV|FBMS|FB_IAB|FB4A|FBAN\/Messenger/.test(agent);

		console.log('isEmbeddedBrowser', isEmbeddedBrowser)
		//XXX
		setEmbeddedBrower(isEmbeddedBrowser)

		setIsLoading(true)
		try {
    	const user = JSON.parse(localStorage.getItem('hm-user'))
			console.log('user', user)
			if (user) {
				getAthlete(user)
			} else {
				setMessage('We don\'t have a local storage')
			}
			setTimeout(() => {
				setIsLoading(false)
			}, 300);
		} catch (err) {
			const message = JSON.stringify(err)
			setMessage(message)
			setTimeout(() => {
				setIsLoading(false)
			}, 300);
		}

  }, [])

	const testAPI = async () => {
		setIsLoading(true)
		let message = ''
		try {
			const response = await fetch('https://api.heightmeters.com/circles/65e0d555ed18798c1fa9ce3e');
			const circle = await response.json()
			message = circle.name
			setTimeout(() => {
				setIsLoading(false)
			}, 300);
		} catch (err) {
			message = JSON.stringify(err)
			setTimeout(() => {
				setIsLoading(false)
			}, 300);
		}
		setMessage(message)
	}

	const goHome = () => {
 	 setIsLoading(true);
 	 if (user && user._id) {
 		 setCircle(null)
 		 setAthlete(user)
 		 //setShowACircle(false)
 		 setCircleIndex(0)
 	 } else {
 		 setUser(null)
 	 }
 	 setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }

	function logout () {
		setIsLoading(true)
		localStorage.removeItem('hm-user')
		setTimeout(() => {
			setCircle(null)
			setAthlete(null)
			setUser(null)
		}, 500);
	}

const getLink = () => {
	return (
			<a
				href="https://test.heightmeters.com?12345"
				target="_blank"
				rel="noopener noreferrer"
				style={{ color: '#444444', fontWeight: 200, lineHeight: '20px', fontSize: '20px', marginTop: "4px" }}>
				Open me in a real browser please 12345
			</a>
		);
}

// window open approach

const open = () => {
	console.log('open https://www.heightmeters.com/')
	//window.open(urlData.url, '_blank'); // open url here
	const newWindow = window.open('https://www.heightmeters.com/', '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}

  return (
		<>
			<Header profile={(athlete && user && user.strava) ? user.strava.profile : null} logout={logout} goHome={goHome} />
			{isLoading ? (
		    <Row justify-content="center" style={{marginTop: '140px'}}>
					<LoadingSpinner />
		    </Row>
			) : (
				<Block>
					<Row justify-content="center" style={{marginTop: '30px', fontWeight: 300, lineHeight: '20px', fontSize: '20px',}}>
						Hej hopp
			    </Row>
					{embeddedBrower ? (
						<Block>
							<Row justify-content="center" style={{marginTop: '20px'}}>
								We are embedded:
							</Row>
							<Row justify-content="center" style={{margin: '20px'}}>
								{userAgent}
							</Row>
							<Row justify-content="center" style={{marginTop: '20px'}}>
								{getLink()}
							</Row>
							<Row justify-content="center" style={{marginTop: '20px'}}>
								<Link style={{ color: '#444444', fontWeight: 200, lineHeight: '20px', fontSize: '20px', marginTop: "4px" }} onClick={() => open()}>
									Approach Open Window
								</Link>
							</Row>
						</Block>
					) : (
						<Block>
							<Row justify-content="center" style={{marginTop: '20px'}}>
								<Link style={{ color: '#444444', fontWeight: 200, lineHeight: '20px', fontSize: '20px', marginTop: "4px" }} onClick={() => testAPI()}>
									Test API
								</Link>
							</Row>
							<Row justify-content="center" style={{margin: '20px'}}>
								User agent: {userAgent}
							</Row>
							{message && (
								<Row justify-content="center" style={{marginTop: '20px'}}>
									{message}
								</Row>
							)}
						</Block>
					)}
				</Block>
  		)}
		</>
	)

}
