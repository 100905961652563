import React from "react";
import "./spinner.css";

export default function LoadingSpinner() {
  return (
    <div className="container">
      <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/26344/bike_wheel-512.png" className="thing-to-spin"/>
    </div>
  );
}
