import React from "react";
import React, { useEffect, useState } from "react";
import { Block, Row } from 'jsxstyle'
import { Link } from "react-router-dom";

export default function Embedded() {

	const [isCopied, setIsCopied] = useState(false);

	const copyUrl = async () => {
		const text = 'https://www.heightmeters.com'
		console.log('Gonna copy URL to clip board', text)
		try {
			await navigator.clipboard.writeText(text);
			console.log('Content copied to clipboard');
			setIsCopied(true)
		} catch (err) {
			console.error('Failed to copy: ', err);
		}
	}

	return (
		<Row justify-content="center" style={{marginTop: "10px"}}>

			<Block style={{ fontWeight: 300, lineHeight: '21px', fontSize: '19px', margin: '20px', marginTop:'0px', marginBottom: '10px', maxWidth: '500px', marginRight: '30px', marginLeft: '30px'}}>

				<div style={{textAlign: "center"}}>
					Oops - we are inside Messangers embedded browser!
				</div>

				<div style={{textAlign: "center", marginTop: '10px'}}>
					Copy URL below and open it in your favorite browser.
				</div>

				<div style={{textAlign: "center", marginTop: '10px'}}>
					https://www.heightmeters.com
				</div>

				<Row justify-content="center">
					{isCopied ? (
						<Row justify-content="center" style={{ fontSize: '17px', marginTop: '5px'}}>
							Link is copied
						</Row>
					) : (
						<Link style={{ fontSize: '17px', marginTop: '3px'}} onClick={() => copyUrl()}>
							copy
						</Link>
					)}
				</Row>

			</Block>

		</Row>
	)

}
