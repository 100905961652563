import { Link } from "react-router-dom";
import { Block, Row, Col } from 'jsxstyle'
import strava_compatible from '../../public/api_logo_cptblWith_strava_horiz_gray.png';

const Footer = () => {

	//					{`©Heightmeters`}

  /*


#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 2.5rem;    // Footer height
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;            //Footer height
}
*/





  return <footer style={{ textAlign: "center", fontWeight: 300, lineHeight: '16px', fontSize: '15px', letterSpacing: '1px'}}>
			<Row justify-content="center">
				<Col style={{margin: 3}}>
					<Link to="/" style={{ textDecoration: 'none', color: '#444444' }}>{`©Heightmeters`}</Link>
				</Col>
				<Col style={{margin: 3}}>
					&#124;
				</Col>
				<Col style={{margin: 3}}>
					<Link to="/about" style={{ textDecoration: 'none', color: '#444444' }}>About</Link>
				</Col>
				<Col style={{margin: 3}}>
					&#124;
				</Col>
				<Col style={{margin: 3}}>
					<Link to="/privacy" style={{ textDecoration: 'none', color: '#444444' }}>Privacy policy</Link>
				</Col>
			</Row>
			<Row justify-content="center">
				<img src={strava_compatible} alt="Strava Compatible" style={{width: '130px', marginTop: '3px'}}/>
			</Row>
		</footer>;
};

export default Footer;
