import React from "react";
import { Block, Row } from 'jsxstyle'
import Header from "../components/header";
import { Link } from "react-router-dom";

export default function Privacy() {

  return (
    <>
      <Header/>
			<Row justify-content="center">
				<Block style={{ fontWeight: 300, lineHeight: '19px', fontSize: '17px', margin: '20px', marginTop:'0px', marginBottom: '0px', maxWidth: '500px' }}>

	        <Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
	          Privacy Policy
	        </Row>
	        <Row style={{ marginBottom: '15px' }}>
	          At Heightmeters.com, we take your privacy very seriously. This Privacy Policy describes how we collect, use, and protect your personal information when you use our website.
	        </Row>

	        <Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
	          Information Collection and Use
	        </Row>
	        <Row style={{ marginBottom: '15px' }}>
	          We collect information from Strava.com's public APIs about bike rides that are publicly shared by users who have agreed to Strava's Terms of Service. This information includes the start and end times, distances, and elevations of bike rides, as well as the Strava usernames of the riders. We use this information to calculate rankings of bike riders based on the elevation gains of their rides. We do not collect any other personal information about you.
	        </Row>

	        <Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
	          Information Sharing and Disclosure
	        </Row>
	        <Row style={{ marginBottom: '15px' }}>
	          We do not share your personal information with any third party, except as required by law. We do not sell or rent your personal information to any third party. We do not use your personal information for any commercial purpose.
	        </Row>

	        <Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
	          Data Security
	        </Row>
	        <Row style={{ marginBottom: '15px' }}>
	          We use industry-standard security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee the absolute security of your personal information.
	        </Row>

	        <Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
	          Cookies
	        </Row>
	        <Row style={{ marginBottom: '15px' }}>
	          We use cookies to personalize your experience on our website. A cookie is a small text file that is stored on your computer or mobile device when you visit our website. It contains information about your visit, such as your preferences and the pages you visited. We use cookies to remember your preferences and to provide you with personalized content and advertising.
	        </Row>

	        <Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
	          Changes to this Privacy Policy
	        </Row>
	        <Row style={{ marginBottom: '15px' }}>
	          We reserve the right to modify this Privacy Policy at any time, so please review it frequently. If we make material changes to this policy, we will notify you here, by email, or by means of a notice on our homepage.
	        </Row>

	        <Row style={{ marginBottom: '5px', fontSize: '20px', fontWeight: 300 }}>
	          Contact Us
	        </Row>
	        <div style={{ marginBottom: '15px' }}>
	          If you have any Questions or concerns about this Privacy Policy, please contact us at <Link to="mailto:heightmeters@gmail.com" style={{ textDecoration: 'none', color: '#444444' }}>heightmeters@gmail.com</Link>
	        </div>

	      </Block>
			</Row>
    </>
  );

}
