import { Link } from "react-router-dom";
import { useState, useEffect } from 'react'
import { Block, Col, Row } from 'jsxstyle'
import { timePeriods } from '../util.js'

const API_URL = process.env.REACT_APP_API_URL

export default function Achievements({achievement, period}) {

	const getMeters = (achievement) => {
		return achievement.length === 1 ? Math.round(achievement[0].meters) : 0
	}

	const getHOD = (achievement) => {
		return achievement.length === 1 ? achievement[0].HOD : 0
	}

	return (
		<>
			{achievement && (
				<Block id="name" style={{ textAlign: 'center', marginBottom: '18px', marginTop: '4px'}}>
					<Row justify-content="center" style={{fontSize: '20px', paddingBottom: '6px'}} >
						<Col>
							<div style={{ width: '120px', letterSpacing: '0.5px'}}>
								{timePeriods[period]}
							</div>
						</Col>
					</Row>
					<Row justify-content="center" style={{fontSize: '25px', fontWeight: 300}} >
						<Col style={{margin: '3px'}}>
							{Math.round(getMeters(achievement)).toLocaleString().replace(',', ' ')}
						</Col>
						<Col style={{margin: '3px', marginTop: "7px", fontSize: '14px'}}>
							HM
						</Col>
						<Col style={{margin: '3px', fontSize: '22px'}}>
							|
						</Col>
						<Col style={{margin:'3px'}}>
							{getHOD(achievement)}
						</Col>
						<Col style={{margin: '3px', marginTop: "7px", fontSize: '14px'}}>
							HOD
						</Col>
					</Row>
				</Block>
			)}
		</>
	)

}
