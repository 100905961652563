import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Block, Row } from 'jsxstyle'
import LoadingSpinner from "../components/spinner";
import { getTimeFromDate } from "../util"

const API_URL = process.env.REACT_APP_API_URL

export default function Trophies({ circleInfo, athleteId }) {

  const [circles, setCircles] = useState([]);
  const [trophies, setTrophies] = useState([]);
  const [type, setType] = useState('Meters');
	const [sort, setSort] = useState('Latest');
	const [isLoadingTrophies, setIsLoadingTrophies] = useState(false);
	//const [gonnaShowCircle, setGonnaShowCircle] = useState(false);

  useEffect( () => {
    setCircles(circleInfo)
    getTrophies(athleteId)
  }, [])

	useEffect( () => {
		getTrophies(athleteId)
	}, [type, sort])

  const getTrophies = async (id) => {
		setIsLoadingTrophies(true)
    //const url = API_URL + 'trophies/athlete/' + id + '/' + type + '/' + sort
		const url = API_URL + 'trophies/athlete/' + id + '/' + sort
    const response = await fetch(url);
    const trophies = await response.json()

    setTrophies(trophies)
		setTimeout(async () => {
      setIsLoadingTrophies(false)
    }, 250);
  }

  const getCircleName = (circleId) => {
    const circle = circles.filter(circle => circle.id === circleId)
    return circle[0].name
  }

  const getTrophyValue = (trophy) => {
    return trophy.type === 'HOD' ? trophy.HOD : Math.round(trophy.heightMeters).toLocaleString().replace(',', ' ')
  }

  const getCopy = (trophy) => {
    return getCircleName(trophy.circle) + " " + (trophy.type === 'meters' ? 'HM' : trophy.type) + " " + getTime(trophy) + " " + getTrophyValue(trophy)
  }

	const getTrophyIcon = (trophy) => {
		if (trophy.rank === 1) {
			return (
					<td style={{ paddingRight: 5, paddingBottom: 2 }}>&#127942;</td>
			)
		}
		if (trophy.rank === 2) {
			return (
					<td style={{ paddingRight: 5, paddingBottom: 2 }}>&#129352;</td>
			)
		}
		if (trophy.rank === 3) {
			return (
					<td style={{ paddingRight: 5, paddingBottom: 2 }}>&#x1F949;</td>
			)
		}
	}



	const Record = (trophy, i) => (
     <tr key={i}>
       {getTrophyIcon(trophy)}
			 <td>{getCircleName(trophy.circle)}</td>
			 <td style={{ paddingRight: 5, paddingBottom: 2 }}>{getTimeFromDate(trophy.date, trophy.period)}</td>
			 <td style={{ paddingRight: 5, paddingBottom: 2 }}>{trophy.type === 'meters' ? 'Meters: ' + getTrophyValue(trophy) : 'HOD: ' + getTrophyValue(trophy)}</td>
     </tr>
    );

	/* CIRCLE
	<td style={{ paddingRight: 5, paddingBottom: 2 }}>
		 {gonnaShowCircle ? (
				<Link onClick={() => showCircle(trophy.circle)} style={{ textDecoration: 'none', fontSize: '16px' }}>
					 {getCircleName(trophy.circle)}
			 </Link>
		 ) : (
			 <>
			 {getCircleName(trophy.circle)}
			 </>
		 )}
	</td>
	*/

  const renderTrophies = () => {

    const body = trophies.map((trophy, i) => {
    	return Record(trophy, i)
    })

		return (
			<table className="table table-striped" style={{ textAlign: "left", lineHeight: '18px', fontSize: '16px', marginTop: '6px'}}>
				<thead style={{ marginTop: 8 }}>
					<tr style={{ fontSize: "16px", fontWeight: 100 }}>
						<th style={{ paddingRight: 5, fontWeight: 300 }}>#</th>
						<th style={{ paddingRight: 5, fontWeight: 300 }}>Circle</th>
						<th style={{ paddingRight: 5, fontWeight: 300 }}>Period</th>
						<th style={{ paddingRight: 5, fontWeight: 300 }}>Achievement</th>
					</tr>
				</thead>
				<tbody>
					{body}
				</tbody>
			</table>
		)
  }

	const renderSortBar = () => {
		return (
			<Block>
				<Row justify-content="center" style={{ fontSize: '18px'}}>
					 {['Latest', 'Greatest'].map((sortType, i) => (
						 <div key={i}>
							 {sortType === sort ? (
								 <Link>
									 {sortType}
								 </Link>
							 ) : (
								 <Link onClick={() => setSort(sortType)} style={{ textDecoration: 'none' }}>
									 {sortType}
								 </Link>
							 )}
								&nbsp;&nbsp;
						 </div>
					 ))}
				</Row>
			</Block>
		)
	}

	const renderAchievementBar = () => {
		return (
			<Block>
				<Row justify-content="center" style={{ fontSize: '16px'}}>
					 {['Meters', 'HOD'].map((effortType, i) => (
						 <div key={i}>
							 {effortType === type ? (
								 <Link>
									 {effortType}
								 </Link>
							 ) : (
								 <Link onClick={() => setType(effortType)} style={{ textDecoration: 'none' }}>
									 {effortType}
								 </Link>
							 )}
								&nbsp;&nbsp;
						 </div>
					 ))}
				</Row>
			</Block>
		)
	}

  return (
    <Block style={{ width: '100%', marginTop: '6px' }} >
			{isLoadingTrophies ? (
				<Row justify-content="center" style={{ marginTop: '20px' , marginBottom: '15px'}}>
					<LoadingSpinner />
				</Row>
			) : (
				<>
					<Row justify-content="center" style={{ marginBottom: '2px', marginTop: '0px' }}>
						{renderSortBar()}
					</Row>
					<Row justify-content="center" style={{ lineHeight: '18px', fontSize: '16px'}} >
		      	{renderTrophies()}
					</Row>
				</>
			)}
    </Block>
  );

/*
<Row justify-content="center" style={{ marginBottom: '0px', marginTop: '0px' }}>
	{renderAchievementBar()}
</Row>
*/



}
