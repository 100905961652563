import { stringifyParams } from './util'

export const oauth = ({ scope }) =>

  new Promise((resolve, reject) => {
    const state = Date.now().toString()
    const screenWidth = window.screen.width
    const screenHeight = window.screen.height
    const width = Math.min(480, screenWidth)
    const height = Math.min(640, screenHeight)
    const left = screenWidth / 2 - width / 2
    const top = screenHeight / 2 - height / 2
    const params = {
      scope,
      state
    }

    const OAUTH_URL = process.env.REACT_APP_STRAVA_OAUTH_URL
		/*
    console.log('OAUTH_URL', OAUTH_URL)
    console.log('Strava window url', `${OAUTH_URL}?${stringifyParams(params)}`)
		console.log('params', params)
		*/
    const win = window.open(
      `${OAUTH_URL}?${stringifyParams(params)}`,
      'oauth',
      `width=${width},height=${height},left=${left},top=${top}`
    )
    let result

    const onMessage = (e) => {
			if (!result) {
				try {
					//console.log('e.data', e.data)
					//console.log('state', state)
		      const data = JSON.parse(e.data)
		      if (data.state === state) {
		        result = data
		        win.close()
		      }
				} catch(err) {
					//console.log('error', err)
				}
			}
    }

    window.addEventListener('message', onMessage)

    const interval = setInterval(() => {
      if (win.closed) {
        clearInterval(interval)
        window.removeEventListener('message', onMessage)
        if (result) {
          resolve(result)
        } else {
          reject(new Error('not authorized'))
        }
      }

    }, 500)

  })
