import React from "react";
import { useRef, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Block, Row, Col } from 'jsxstyle'
import Header from "../components/header";
import LoadingSpinner from "../components/spinner";
import LeaderBoard from "../components/leaderboard";
import CircleInfo from "../components/circle-info";

const API_URL = process.env.REACT_APP_API_URL

export default function Circle({circle, getCircle, changeAthlete, period, setSorted, sorted, updatePeriod}) {

	const [isLoading, setIsLoading] = useState(false);

  return (
    <>
    	<Row justify-content="center">
	      {isLoading ? (
	        <Row justify-content="center">
	          <LoadingSpinner />
	        </Row>
	      ) : (
	        <Block>
						<CircleInfo circle={circle} changeAthlete={changeAthlete}/>
						<Row justify-content="center" style={{ margin: '7px', fontSize: '22px' }}>
							Leaderboard
						</Row>
						<LeaderBoard
							onACircle={true}
							changeAthlete={changeAthlete}
							period={period}
							circle={circle}
							setSorted={setSorted}
							sorted={sorted}
							updatePeriodAndTime={updatePeriodAndTime}
							time={time}
							/>

	        </Block>
	      )}
      </Row>
    </>
  );

}
